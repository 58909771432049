import { DateTime } from 'luxon';

export const addZero = (x) => {
  if (x === 0) return "00";
  return x >= 10 ? x : `0${x}`;
};

export const getTimeLeft = (config) => {
  const now = DateTime.now();
  const then = DateTime.fromFormat(config.TRAVEL_DATE, 'yyyy-LL-dd HH:mm:ss');
  const diff = then.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();

  return {
    days: addZero(diff.days),
    hours: addZero(diff.hours),
    minutes: addZero(diff.minutes),
    seconds: addZero(parseInt(diff.seconds))
  };
};

export const getTravelDate = (config) => {
  return DateTime.fromFormat(config.TRAVEL_DATE, 'yyyy-LL-dd HH:mm:ss').toISO();  
};

const flipFormats = {
  WITH_SECONDS: ['d', 'h', 'm', 's'],
  WITHOUT_SECONDS: ['d', 'h', 'm'],
};

export const getFlipFormat = (forWidget) => {
  if (forWidget) {
    return flipFormats.WITHOUT_SECONDS;
  }

  return flipFormats.WITH_SECONDS;
};
