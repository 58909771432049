import { FlipClock } from '../Flip';
import { getFlipFormat, getTravelDate } from '../utils';

export default function Widget({ config }) {

  return (
    <div className='app widget' style={{ backgroundImage: 'url(/frapcon_destination_bg.jpg)' }}>
      <div className='grid-container'>
        <div className='item item1'>Nedräkning till {config.DESTINATION}</div>
        <div className='item-clock'>
          {
            <FlipClock date={getTravelDate(config)} format={getFlipFormat(true)} />
          }
        </div>
        <div className="item" />
      </div>
    </div>
  );
}
