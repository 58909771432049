import { StrictMode } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import config from './config';
import FrapConWeb from './frapCon/Web';
import FrapConWidget from './frapCon/Widget';
import VacationWeb from './vacation/Web';
import VacationWidget from './vacation/Widget';
import './index.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Router>
    <div>
      <StrictMode>
        <Routes>
          <Route path="/vacation" element={<VacationWeb config={config.vacation} />} />
          <Route path="/vacation/widget" element={<VacationWidget config={config.vacation} />} />
          <Route path="/frapCon/widget" element={<FrapConWidget config={config.frapCon} />} />
          <Route path="/frapCon" element={<FrapConWeb config={config.frapCon} />} />
          <Route path="/" element={<FrapConWeb config={config.frapCon} />} />
        </Routes>
      </StrictMode>
    </div>
  </Router>,
  rootElement
);

let trailArr = [1, .9, .8, .5, .25, .6, .4, .3, .2];

function trailAnimation(e, i, callbackFn) {
  var elem = document.createElement('div');

  elem = styleSparkle(elem, e, i);

  if (typeof callbackFn == 'function') {
    elem = callbackFn(elem);    
  }
  
  elem.classList.add("sparkle");

  document.body.appendChild(elem);

  window.setTimeout(function () {
    document.body.removeChild(elem);
  }, Math.round(Math.random() * i * 1000));
}

function styleSparkle(elem, e, i) {
  let j = (1 - i) * 150;
  let size = Math.ceil(Math.random() * 10 * i) + 'px';
  
  elem.style.top = e.pageY - window.scrollY + Math.round(Math.random() * j - j / 2) + 'px';
  elem.style.left = e.pageX + Math.round(Math.random() * j - j / 2) + 'px';
  
  elem.style.width = size;
  elem.style.height = size;
  elem.style.borderRadius = size;
  
  elem.style.background = 'hsla(' +
    Math.round(Math.random() * 200) + ', ' +
    '80%, ' +
    '90%, ' +
    i + ')';
  
  return elem;
}

window.addEventListener('mousemove', function (e) {
  trailArr.forEach((i) => {trailAnimation(e, i)});

  trailArr.forEach((i) => {trailAnimation(e, i, (elem) => {
    elem.style.animation = "fallingsparkles 1s";
    
    return elem;
  })});
}, false);
