const config = {
  frapCon: {
    DESTINATION: 'Split, Kroatien',
    TRAVEL_DATE: '2024-05-01 06:30:00',
  },
  vacation: {
    DESTINATION: 'Mallorca, Spanien',
    TRAVEL_DATE: '2024-07-02 07:50:00',
  },
  tenerife: {
    DESTINATION: 'Teneriffa, Spanien',
    TRAVEL_DATE: '2025-01-03 00:00:00',
  }
};

export default config;
