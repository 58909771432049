import { FlipClock } from '../Flip';
import { getFlipFormat, getTravelDate } from '../utils';
import "../App.css";

export default function Web({ config }) {
  return (
    <div className='app' style={{ backgroundImage: 'url(/frapcon_destination_bg.jpg)' }}>
      <div className='grid-container'>
        <div className='item item1'>Nedräkning till {config.DESTINATION}</div>
        <div className='item-clock'>
          <FlipClock date={getTravelDate(config)} format={getFlipFormat()} />
        </div>
        <div className="item" />
      </div>
    </div>
  );
}
