import React, { useEffect, useRef } from "react";

import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import "./tick.css";

export default class Flip extends React.Component {
  constructor(props) {
    super(props);
    this._tickRef = React.createRef();
  }

  componentDidMount() {
    this._tickInstance = Tick.DOM.create(this._tickRef.current, {
      value: this.props.value
    });
    this._tickInstance.setConstant('DAY_PLURAL', 'Dagar');
  }

  componentDidUpdate() {
    if (!this._tickInstance) return;
    this._tickInstance.value = this.props.value;
  }

  componentWillUnmount() {
    if (!this._tickInstance) return;
    Tick.DOM.destroy(this._tickRef.current);
  }

  render() {
    return (
      <div ref={this._tickRef} className="tick" data-layout="fit">
        <div data-repeat="true" data-layout="horizontal" aria-hidden="true">
        {/* <div data-repeat="true" data-layout="horizontal fit" data-transform="preset(d, h, m, s) -> delay" aria-hidden="true"> */}
          <div className="tick-group">
            <div data-key="value" data-repeat="true">
            {/* <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay"> */}
              <span data-view="flip"></span>
            </div>
            <span data-key="label" data-view="text" className="tick-label" />
          </div>
        </div>
      </div>
    );
  }
};

export const FlipClock = ({ date, format }) => {
  const flipRef = useRef(null);

  useEffect(() => {
    console.log('Flip date', date);
    const tick = Tick.DOM.create(flipRef.current);
    if (flipRef.current && tick) {
      handleInit(tick);
    }
  }, [date, flipRef]);

  const handleInit = (tick) => {
    console.log('Flip tick', tick);
    var locale = {
      YEAR_PLURAL: 'År',
      YEAR_SINGULAR: 'År',
      MONTH_PLURAL: 'Månader',
      MONTH_SINGULAR: 'Månad',
      WEEK_PLURAL: 'Veckor',
      WEEK_SINGULAR: 'Vecka',
      DAY_PLURAL: 'Dagar',
      DAY_SINGULAR: 'Dag',
      HOUR_PLURAL: 'Timmar',
      HOUR_SINGULAR: 'Timma',
      MINUTE_PLURAL: 'Minuter',
      MINUTE_SINGULAR: 'Minut',
      SECOND_PLURAL: 'Sekunder',
      SECOND_SINGULAR: 'Sekund',
    };

    for (var key in locale) {
      tick.setConstant(key, locale[key]);
    }

    const counter = Tick.count.down(date, { format });
    counter.onupdate = function(value) {
      tick.value = value;
    };
  }

  return (
    <div ref={flipRef} className="tick">
      <div data-repeat="true" data-layout="horizontal" data-transform="preset(d, h, m, s) -> delay" aria-hidden="true">
        <div className="tick-group">
          <div data-key="value" data-repeat="true" data-transform="pad(00) -> split -> delay">
            <span data-view="flip"></span>
          </div>
          <span data-key="label" data-view="text" className="tick-label" />
        </div>
      </div>
    </div>
  );
}
